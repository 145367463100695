const themeOptions = {
    status: {
        tertiary: '#5A6BBC',
        quaternary: '#F2B544',
        gray: '#999',
    },
    palette: {
        primary: {
            light: '#3e496b',
            main: '#132240',
            dark: '#00001b',
            contrastText: '#fff',
        },
        secondary: {
            light: '#8c99ef',
            main: '#5A6BBC',
            dark: '#24418b',
            contrastText: '#fff',
        },
        tertiary: {
            light: '#f78648',
            main: '#bf571b',
            dark: '#882900',
            contrastText: '#fff',
        },
        quaternary: {
            light: '#F4C369',
            main: '#F2B544',
            dark: '#A97E2F',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        gray: {
            light: '#BBB',
            main: '#AAA',
            dark: '#767676',
            contrastText: '#FFF',
        },
    },
    typography: {
        fontFamily: [
            'Noto Sans',
            'Helvetica',
            'Arial',
            'sans-serif',
        ].join(','),
        fontSize: 12,
        h3: {
            fontSize: "1.05rem",
            // [theme.breakpoints.up('md')]: {
            //     fontSize: '2.4rem',
            // },
        },
        cardTitle: {
            fontSize: "0.9rem",
            fontWeight: 700,
        },
        button: {
            fontWeight: 700,
            textTransform: 'none',
            '&:hover': {
                color: '#00001b',
            },
        }
    },
    components: {
        // MuiButton: {
        //     root: ({ownState}) => ({
        //         '&:hover': {
        //             color: '#fff',
        //         },
        //     })
        // }
    },
    overrides: {
        MuiFab: {
            root: {
                fontWeight: 700,
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "16px",
            },
        },
    }
    //spacing: 8,
};

export default themeOptions;