import React, {useState} from 'react';
import {withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader} from '@mui/material';
import {useAuth} from "../../context/auth-context";
import SaveRouteListItemComponent from "./SaveRouteListItemComponent";
import Constants from "../../config/constants";
import useStoredRouteClear from "../../hooks/useStoredRouteClear";
import {MuiButton} from "../MuiButton";
import {toast} from "react-toastify";

const SaveRouteListComponent = ({i18n, t, onClose, customerRoutes, user}) => {
    const auth = useAuth();
    const [askDelete, setAskDelete] = useState(false);
    const {mutateAsync, status, error } = useStoredRouteClear(i18n.language);

    const multipitchRoutes = customerRoutes.filter((route) => route.type === Constants.TypeTopo.GRANDEVOIE).sort(function(a, b) {
        return a.label.localeCompare(b.label);
    });

    const cliffRoutes = customerRoutes.filter((route) => route.type === Constants.TypeTopo.FALAISE).sort(function(a, b) {
        return a.label.localeCompare(b.label);
    });

    const clearAllRoutes = async () => {
        try {
            await mutateAsync(i18n.language);
        } catch (e) {
            toast.error(t('common.error.delete'));
        }
    }

    return (<>
        {customerRoutes.length > 0 && <List component="div" disablePadding>
            <List>
                <ListItem>
                <ListItemText primary={<span>{t("Navbar.saveRoute.clearAll")}</span>}
                               secondary={askDelete ? <>
                                       <p style={{margin: "0.5rem 0"}}>{t("Navbar.saveRoute.delete.confirmAll")}</p>
                                       <Box display="flex" alignItems="flex-start" style={{textAlign: "center"}}>
                                           <Box flexGrow={1}>
                                               <MuiButton variant="contained" color="gray" edge="start" aria-label="delete" onClick={() => setAskDelete(false)}>
                                                   {t("common.cancel")}
                                                   {/*<FontAwesomeIcon icon={["fas", "times"]} size="sm" />*/}
                                               </MuiButton>
                                           </Box>
                                           <Box flexGrow={1}>
                                               <MuiButton variant="contained" color="primary" edge="end" aria-label="delete" onClick={(e) => {e.preventDefault(); clearAllRoutes();}}>
                                                   {t("common.confirm")}
                                                   {/*<FontAwesomeIcon icon={["fas", "check"]} size="sm" />*/}
                                               </MuiButton>
                                           </Box>
                                       </Box>
                                   </>
                                   : null } />

                    <ListItemSecondaryAction>
                        {(status === "success" || status === "idle") && !askDelete && <IconButton edge="end" aria-label="delete" onClick={() => setAskDelete(true)}>
                            <FontAwesomeIcon icon={["fas", "trash"]} size="sm" className="text-danger" />
                        </IconButton>}
                        {(status === "success") && askDelete && <>

                        </>}
                        {status === "pending" && <IconButton edge="end" aria-label="loading">
                            <FontAwesomeIcon icon={["fas", "spinner"]} size="sm" spin className="secondary" />
                        </IconButton>}
                        {status === "error" && <IconButton edge="end" aria-label="error">
                            <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} size="sm" className={"text-danger"} />
                        </IconButton>}
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            {/*Showing saveroute item while deleting them is not a good idea */}
            {status !== "pending" && multipitchRoutes.length > 0 && <>
                <ListSubheader disableSticky className={"storeRouteHeader"}>{t("Navbar.saveRoute.multipitch")}</ListSubheader>
                {multipitchRoutes.map((multipitchRoute) => {
                    return (<SaveRouteListItemComponent user={user} saveRoute={multipitchRoute} key={multipitchRoute.route.id} onClose={onClose}/>);
                })}
            </>}
            {status !== "pending" && cliffRoutes.length > 0 && <>
                <ListSubheader disableSticky className={"storeRouteHeader"}>{t("Navbar.saveRoute.cliff")}</ListSubheader>
                {cliffRoutes.map((cliffRoute) => {
                    return (<SaveRouteListItemComponent user={user} saveRoute={cliffRoute} key={cliffRoute.route.id} onClose={onClose}/>);
                })}
            </>}
        </List>}
        {!auth.isAuthenticated && <List component="div" disablePadding>
            <ListItem>
                <ListItemText primary={t("Navbar.saveRoute.guest")} />
            </ListItem>
        </List>}
        {auth.isAuthenticated && customerRoutes.length === 0 && <List component="div" disablePadding>
            <ListItem>
                <ListItemText  className={"text-danger"} primary={t("Navbar.saveRoute.empty")} />
            </ListItem>
        </List>}
    </>);
};

export default withTranslation()(SaveRouteListComponent);