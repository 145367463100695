import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, IconButton, ListItem, ListItemSecondaryAction, ListItemText} from '@mui/material';
import useStoredRouteUpdate from "../../hooks/useStoredRouteUpdate";
import {MuiButton} from "../MuiButton";
import {getUrl, PWAUrls} from "../../config/urls";
import {getCliffTypeFromLocale, getMultipitchTypeFromLocale, getStoreRouteCacheTime} from "../../utils/helpers";
import Constants from "../../config/constants";

/**
 * Composant de SaveRouteListItemComponent
 */
const SaveRouteListItemComponent = ({i18n, t, saveRoute,  onClose}) => {
    const {mutateAsync, status: status, error: error } = useStoredRouteUpdate(i18n.language);
    const removeCustomerRoute = async (id) => {
        await mutateAsync({
            id: id,
            action: 'remove',
        });
    }

    let link;
    if (saveRoute.type === Constants.TypeTopo.FALAISE) {
        link = getUrl(PWAUrls.SECTOR_ROUTE, {lng: i18n.language, routeType: getCliffTypeFromLocale(i18n.language), slugRegion: saveRoute.route.region.slug, slugGroupRoute: saveRoute.route.cliff.slug, slug: saveRoute.route.slug});
    } else {
        link = getUrl(PWAUrls.MULTIPITCH_ROUTE, {lng: i18n.language, routeType: getMultipitchTypeFromLocale(i18n.language), slugRegion: saveRoute.route.region.slug, slug: saveRoute.route.slug});
    }

    const cacheTime = getStoreRouteCacheTime(saveRoute.endValidationDate);

    const [askDelete, setAskDelete] = useState(false);
    return (
        <ListItem>
            <ListItemText primary={<Link to={link} onClick={() => {onClose(false);}}>{saveRoute.label}</Link>}
                          secondary={<>
                              {cacheTime <= 0 && <p className={"text-danger my-1"}>{t("Navbar.saveRoute.activationOver")}</p>}
                              {askDelete ? <>
                            <p className={"my-1"}>{t("Navbar.saveRoute.delete.confirm")}</p>
                              <Box display="flex" alignItems="flex-start" style={{textAlign: "center"}}>
                                  <Box flexGrow={1}>
                                      <MuiButton variant="contained" color="gray" edge="start" aria-label="delete" onClick={(e) => {e.preventDefault(); setAskDelete(false)}}>
                                          {t("common.cancel")}
                                          {/*<FontAwesomeIcon icon={["fas", "times"]} size="sm" />*/}
                                      </MuiButton>
                                  </Box>
                                  <Box flexGrow={1}>
                                      <MuiButton variant="contained" color="primary" edge="end" aria-label="delete" onClick={(e) => {e.preventDefault(); removeCustomerRoute(saveRoute.route.id)}}>
                                          {t("common.confirm")}
                                          {/*<FontAwesomeIcon icon={["fas", "check"]} size="sm" />*/}
                                      </MuiButton>
                                  </Box>
                              </Box>
                        </>
                          : null }
                          </>} />
            <ListItemSecondaryAction>
                {(status === "success" || status === "idle") && !askDelete && <IconButton edge="end" aria-label="delete" onClick={() => setAskDelete(true)}>
                    <FontAwesomeIcon icon={["fas", "trash"]} size="sm" className="primary" />
                </IconButton>}
                {(status === "success" || status === "idle") && askDelete && <>

                </>}
                {status === "pending" && <IconButton edge="end" aria-label="loading">
                    <FontAwesomeIcon icon={["fas", "spinner"]} size="sm" spin className="secondary" />
                </IconButton>}
                {status === "error" && <IconButton edge="end" aria-label="error">
                    <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} size="sm" className={"text-danger"} />
                </IconButton>}
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default withTranslation()(SaveRouteListItemComponent);