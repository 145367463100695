import React, {Component} from 'react';
import {connexionStateChange} from "../actions";
import {connect} from "react-redux";

export default function (ComposedComponent) {
    class NetworkDetector extends Component {
        constructor() {
            super();
            this.state = {
                isDisconnected: false
            }
        }

        componentDidMount() {
            setTimeout(() => {
                this.handleConnectionChange(this);
            }, 5000);

            window.addEventListener('online', () => {this.handleConnectionChange(this)});
            window.addEventListener('offline', () => {this.handleConnectionChange(this)});
        }

        componentWillUnmount() {
            window.removeEventListener('online', () => {this.handleConnectionChange(this)});
            window.removeEventListener('offline', () => {this.handleConnectionChange(this)});
        }


        handleConnectionChange(self) {
            const condition = navigator.onLine ? 'online' : 'offline';
            // console.log('condition: ', condition);
            // const self = this;
            if (condition === 'online') {
                const webPing = setInterval(
                    () => {
                        fetch('https://checkip.amazonaws.com/', {
                            mode: 'no-cors',
                        })
                            .then(() => {
                                self.setState({ isDisconnected: false }, () => {
                                    return clearInterval(webPing)
                                });
                            }).catch(() => {
                                // console.log(self);
                                self.setState({ isDisconnected: true })
                        } )
                    }, 2000);
                return;
            }

            // console.log(this);

            return self.setState({ isDisconnected: true });
        }

        render() {
            this.props.onConnexionStateChange(!this.state.isDisconnected);
            return (
                <div>
                    <ComposedComponent {...this.props} />
                </div>
            );
        }
    }

    const mapDispatchToProps = dispatch => {
        return {
            onConnexionStateChange: (isOnline) => {
                dispatch(connexionStateChange(isOnline))
            }
        }
    }

    return connect(
        null,
        mapDispatchToProps
    )(NetworkDetector);
}