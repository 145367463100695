import React, {useEffect} from 'react';
import { withTranslation} from 'react-i18next';
import {Alert, Typography} from '@mui/material';
import {compose} from "redux";
import {connect} from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useFlashMessageAck} from "../../../hooks/useFlashMessages";
import {toast} from "react-toastify";
import {MuiButton} from "../../MuiButton";

const DialogFlashMessage = ({
    i18n, t, flashMessage
}) => {
    const [open, setOpen] = React.useState(true);
    const { data, mutateAsync, status, error } = useFlashMessageAck(i18n.language);
    const handleClose = (flashMessageId) => {
        try {
            mutateAsync(flashMessageId).then(r => {

            });
            setOpen(false);
        } catch (e) {
            toast.error(t('common.error.save'));
            setOpen(false);
        }
    };

    useEffect(() => {
        setOpen(true);
    }, [flashMessage]);

    return <>
        <Dialog
            open={open}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {flashMessage.label}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {flashMessage.severity && <Alert
                        severity={flashMessage.severity}
                        style={{marginTop: "15px", marginBottom: "15px"}}>
                        <p className="wysiwyg" dangerouslySetInnerHTML={{__html: flashMessage.content}}/>
                        {/*<Typography variant="body1" gutterBottom*/}
                        {/*            dangerouslySetInnerHTML={{__html: flashMessage.content}}/>*/}
                    </Alert>}
                    {!flashMessage.severity && <p dangerouslySetInnerHTML={{__html: flashMessage.content}}></p>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MuiButton onClick={() => handleClose(flashMessage.id)}
                           variant="contained"
                           color="primary">
                    {t("FlashMessage.confirm")}
                </MuiButton>
            </DialogActions>
        </Dialog>
        </>;
};

const mapStateToProps = (state) => {
    return {

    }
};

export default compose(
    withTranslation(),
    connect(
        mapStateToProps
    ))(DialogFlashMessage);
