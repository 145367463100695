import React from 'react'
import { CircularProgress} from "@mui/material";
import {hideFullscreenSpinner, showFullscreenSpinner} from "../actions";
import {compose} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
const FullscreenSpinnerComponent = (props) => {
    const [showWaitingButton, setShowWaitingButton] = React.useState(false);
    const { t } = useTranslation();

    const styles = {
        wrapper: {
            position: "fixed",
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255, 0.7)',
            zIndex: 9999,
        }
    }

    setTimeout(() => {
        setShowWaitingButton(true);
    }, 3500);

    return (
        <div className={'FullscreenSpinner'} style={styles.wrapper}>
            <CircularProgress />
            {showWaitingButton &&
                <button className={'btn btn-primary mt-5'}
                        onClick={() => {
                            console.log('ici');
                            props.hideFullscreenSpinner()
                        }}>
                    { t('FullscreenSpinnerComponent.waitingBtn.title') }
                </button>
            }
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        showFullscreenSpinner: () => {
            dispatch(showFullscreenSpinner())
        },
        hideFullscreenSpinner: () => {
            dispatch(hideFullscreenSpinner())
        },
    }
};

export default compose(
    connect(
        null,
        mapDispatchToProps
    )
)(FullscreenSpinnerComponent);
