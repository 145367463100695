import axios from 'axios'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {ApiLanguages, ApiUrls} from "../config/urls";

export default function useStoredRouteClear(language) {
     const queryClient = useQueryClient();

    return useMutation({
        networkMode: 'always',
        mutationFn: (values) => axios.post(ApiUrls.STORE_ROUTE_CLEAR.replace('{lng}', language), values)
            .then((res) => {
                if (res.data.success === true) {
                    return res.data.data;
                } else {
                    return res.data;
                }
            }),
        onError: (error, _updateStoreRoute, rollback) => {
            if (rollback) rollback()
        },
        onSuccess: async (data) => {
            for (const apiLanguage of ApiLanguages) {
                const customerUrl = ApiUrls.CUSTOMER.replace('{email}', data?.user?.email).replace('{lng}', apiLanguage)
                const sectorRouteQueryKey = [ApiUrls.SECTOR_ROUTE_UNLOCK.replace('{lng}', apiLanguage),];
                const multipitchRouteQueryKey = [ApiUrls.MULTIPITCH_ROUTE_UNLOCK.replace('{lng}', apiLanguage),];
                const cliffRouteQueryKey = [ApiUrls.CLIFF_UNLOCK.replace('{lng}', apiLanguage),];

                queryClient.removeQueries({queryKey:sectorRouteQueryKey});
                queryClient.removeQueries({queryKey:multipitchRouteQueryKey});
                queryClient.removeQueries({queryKey:cliffRouteQueryKey});

                await queryClient.invalidateQueries({queryKey:[customerUrl]});
               // console.log(data, [customerUrl, {email: data?.user?.email, language: language}]);
            }

            const url = ApiUrls.CUSTOMER.replace('{email}', data?.user?.email).replace('{lng}', language);
            queryClient.setQueryData([url, {email: data?.user?.email, language: language}], data);
        }
    })
}
