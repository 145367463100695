import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation, withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Box, Button} from '@mui/material';
import {compose} from "redux";
import {connect} from "react-redux";

const AlertComponent = ({
    title,
    message,
    backgroundIcon: propBackgroundIcon,
    reload: propReload,
    ctaLinkTo: propCtaLinkTo,
    ctaLabel: propCtaLabel,
    ctaIcon: propCtaIcon,
    showOnline: propShowOnline,
    severity: propSeverity,
    backButtonText: propBackButtonText,
    backButtonShow: propBackButtonShow,
    backButtonCallback: propBackButtonCallback,
    isOnline,
}) => {

    const { t } = useTranslation();
    const defaultCallback = () => {};
    const backgroundIcon = propBackgroundIcon ? propBackgroundIcon : "exclamation-triangle";
    const reload = (propReload === true || propReload === false) ? propReload : true;
    const showOnline = (propShowOnline === true || propShowOnline === false) ? propShowOnline : true;
    const severity = propSeverity ? propSeverity : 'info';
    const ctaLinkTo = propCtaLinkTo ? propCtaLinkTo : "/";
    const ctaLabel = propCtaLabel ? propCtaLabel : "";
    const ctaIcon = propCtaIcon ? propCtaIcon : "";
    const backButtonText = propBackButtonText ?? t("AlertComponent.button.back");
    const backButtonShow = propBackButtonShow ?? false;
    const backButtonCallback = propBackButtonCallback ?? defaultCallback;

    let color;
    switch (severity) {
        case 'info':
            color = '#0d3c61';
            break;
        case 'error':
            color = '#611a15';
            break;
        case 'warning':
            color = '#663c00';
            break;
        case 'success':
            color = '#1e4620';
            break;
        default:
            color = '#0d3c61';
            break;
    }

    return (
        <div
            style={{height: "100%", display: 'flex', flexDirection: 'row', }}
        >
            <Box style={{flexGrow: 1}}
                 display="flex"
                 alignItems="center"
                 justifyContent="center"
                 m={2}
            >
                <div style={{textAlign: 'center'}}>
                    <h1 style={{fontSize: '1.2rem', marginBottom: '2rem', color: color}}>
                        {title}
                    </h1>
                    <p className={'mt-3'} style={{color: color}}>{message}</p>

                    {showOnline && !isOnline &&
                    <p className={'mt-3'} style={{color: color}}>{t("AlertComponent.message.offline")}</p>
                    }

                    {reload && isOnline &&
                    <Button variant="contained" color="primary" className={'mt-4'} style={{width: '100%'}} onClick={() => {window.location.reload()}}>
                        <FontAwesomeIcon icon={["fas", "sync"]} /> &nbsp; {t("AlertComponent.button.reload")}
                    </Button>
                    }

                    {ctaLinkTo && ctaLabel &&
                    <Button
                        component={Link}
                        to={ctaLinkTo}
                        variant={reload ? 'text' : 'contained'}
                        color={'primary'}
                        className={'mt-4'}
                        style={{width: '100%', textAlign: 'center'}}>
                        {ctaIcon && <FontAwesomeIcon icon={["fas", ctaIcon]} />} &nbsp; {ctaLabel}
                    </Button>
                    }

                    {backButtonShow &&
                    <div className={'mt-4 mb-4'}>
                        <Link to={'#'} className={'text-center'} onClick={backButtonCallback}>
                            {backButtonText}
                        </Link>
                    </div>
                    }

                </div>
                <div style={{position: 'absolute', top: 'calc(50% - 170px)', fontSize: '120px', textAlign: 'center', width: '150px', height: '150px', color: color, opacity: '0.1'}}>
                    <FontAwesomeIcon icon={["fas", backgroundIcon]} />
                </div>
            </Box>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isOnline: state.common.isOnline,
    }
};

export default compose(
    withTranslation(),
    connect(
        mapStateToProps
    ))(AlertComponent);
