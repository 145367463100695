import * as React from 'react';
import axios from "axios";
import {useQueryClient} from '@tanstack/react-query';
import {ApiUrls, ApiLanguages, getUrl, PWAUrls} from "../config/urls";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Constants from "../config/constants";

/**
 * AuthContext hooks
 *
 * Logic started with the first link and finished with the second (mix the two)
 *
 * @src https://kentcdodds.com/blog/authentication-in-react-applications
 * @src https://www.freecodecamp.org/news/state-management-with-react-hooks/
 *
 */

const loginActions = {
    LOGIN: "LOGIN",
    UPDATE_USER: "UPDATE_USER",
    LOGOUT: "LOGOUT",
    SET_CART: 'SET_CART',
    SET_FLASH_MESSAGES: 'SET_FLASH_MESSAGES',
    SET_ACK_FLASH_MESSAGE_IDS: 'SET_ACK_FLASH_MESSAGE_IDS',
};

const storageConstants = {
    session: 'OMESESSID',
    user: 'OMEUSER',
    jwt: 'OMEJWT',
    cart: 'OMECART',
    flashMessages: 'OMEFLASHMESSAGES',
    ackFlashMessageIds: 'OMEACKFLASHMESSAGEIDS',
};

const AuthContext = React.createContext();

const clearAuthItems = () => {
    localStorage.removeItem(storageConstants.user);
    localStorage.removeItem(storageConstants.session);
    localStorage.removeItem(storageConstants.cart);
    localStorage.removeItem(storageConstants.flashMessages);
    localStorage.removeItem(storageConstants.ackFlashMessageIds);
};

const reducer = (state, action) => {
    switch (action.type) {
        case loginActions.LOGIN:
            let user = action.payload.user;
            user.uniqKey = "" + Date.now();
            localStorage.setItem(storageConstants.user, JSON.stringify(user));
            localStorage.setItem(storageConstants.session, JSON.stringify(action.payload.token));
            localStorage.setItem(storageConstants.cart, JSON.stringify(action.payload.cart));
            return {
                ...state,
                isAuthenticated: true,
                user: user,
                token: action.payload.token,
                cart: action.payload.cart,
            };
        case loginActions.UPDATE_USER:
            localStorage.setItem(storageConstants.user, JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload,
            };
        case loginActions.LOGOUT:
            clearAuthItems();
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null,
                cart: null,
            };
        case loginActions.SET_CART:
            localStorage.setItem(storageConstants.cart, JSON.stringify(action.payload));
            return {
                ...state,
                cart: action.payload,
            };
        case loginActions.SET_FLASH_MESSAGES:
            localStorage.setItem(storageConstants.flashMessages, JSON.stringify(action.payload));
            return {
                ...state,
                flashMessages: action.payload,
            };
        default:
            return state;
    }
};

const getInitialState = () => {
    const initialState = {
        isAuthenticated: false,
        user: null,
        token: null,
        cart: null,
        flashMessages: [],
        ackFlashMessageIds: [],
    };
    if (localStorage.getItem(storageConstants.session) !== null
        && localStorage.getItem(storageConstants.session) !== "undefined"
        && typeof localStorage.getItem(storageConstants.session) !== "undefined") {
        initialState.token = JSON.parse(localStorage.getItem(storageConstants.session));
        initialState.cart = JSON.parse(localStorage.getItem(storageConstants.cart));
        initialState.isAuthenticated = true;
        initialState.flashMessages = JSON.parse(localStorage.getItem(storageConstants.flashMessages));
        initialState.ackFlashMessageIds = JSON.parse(localStorage.getItem(storageConstants.ackFlashMessageIds));
    }
    if (localStorage.getItem(storageConstants.user) !== null
        && localStorage.getItem(storageConstants.user) !== "undefined"
        && typeof localStorage.getItem(storageConstants.user) !== "undefined") {
        initialState.user = JSON.parse(localStorage.getItem(storageConstants.user));
    }

    return initialState;
}

function AuthProvider({children}) {
    const [state, dispatch] = React.useReducer(reducer, getInitialState());

    axios.defaults.headers.common['Content-Type'] = "application/json;charset=UTF-8";

    if (state.isAuthenticated) {
        axios.defaults.headers.common['X-Auth-Token'] = state.token;
        axios.defaults.headers.common['Authorization'] = "Bearer " + state.jwt;
    } else {
        delete axios.defaults.headers.common['X-Auth-Token'];
        delete axios.defaults.headers.common['Authorization'];
    }

    return (
        <AuthContext.Provider value={{
            state,
            dispatch
        }}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = () => {
    const { state, dispatch } = React.useContext(AuthContext);
    const { i18n } = useTranslation();
    const queryClient = useQueryClient();

    const login = (data) => {
        dispatch({
            type: loginActions.LOGIN,
            payload: data
        })
    }

    const updateUser = (data) => {
        dispatch({
            type: loginActions.UPDATE_USER,
            payload: {...data, uniqKey: "" + Date.now()}
        })
    }

    const logout = () => {
        dispatch({
            type: loginActions.LOGOUT
        })
    }

    const isRouteStored = (route, customerRoutes) => {
        if (!Array.isArray(customerRoutes)) {
            return false;
        }

        if (route?.type === Constants.CLIFF) {
            const cliffRoutes = customerRoutes.filter((r) => r.cliff === route.slug);
            return route.enabledSectorCount > 0 && cliffRoutes.length === route.enabledSectorCount;
        }

        const customerRoute = customerRoutes.find((r) => r.slug === route.slug);
        if (route.type === Constants.MULTIPITCH
            && typeof customerRoute === "object") {
            return true;
        }

        return false;
    }

    const setCart = (cart) => {
        dispatch({
            type: loginActions.SET_CART,
            payload: cart
        })
    }
    const getCart = () => {
        return state.cart;
    }


    const cartExists = () => {
        return state.cart !== null;
    }

    const isInCart = ( product ) => {
        return cartExists() &&
            state.cart.products !== undefined &&
            product !== undefined &&
            Object.keys(state.cart.products).some(function(k) {
                return state.cart.products[k] !== undefined && state.cart.products[k].id === product.id;
            });
    }

    const getCartLength = () => {
        if (cartExists() &&
            state.cart.products !== undefined) {
            return state.cart.products.length;
        }

        return null;
    }

    const hasShippedItem = () => {
        return Object.values(state.cart.products).some(function(item) {
            return !item.isVirtual;
        });
    }

    const hasParentGuidebookInCart = (item) => {
        if (Array.isArray(item?.guidebookIds) && Array.isArray(state?.cart?.guidebookProductIds)) {
            const duplicateParentIds = item.guidebookIds.filter(function (val) {
                return state.cart.guidebookProductIds.indexOf(Number.parseInt(val, 10)) !== -1;
            });

            return duplicateParentIds.length > 0;
        }

        return false;
    }

    const getUniqUserKey = () => {
        return state?.user?.uniqKey;
    }

    const hasValidatedGuidebook = (route) => {
        if (state?.user?.validations) {
            //If array intersect
            const filteredArray = Array.isArray(route?.guidebookIds)
                ? state.user.validations.filter(validation => route.guidebookIds.includes(validation.id))
                : [];

            return filteredArray.length > 0;
        }

        return false;
    }

    const getValidationLink = (route, locale = null) => {
        if (state?.user?.validations) {
            //If array intersect
            const filteredArray = Array.isArray(route?.guidebookIds)
                ? state.user.validations.filter(validation => route.guidebookIds.includes(validation.id))
                : [];

            const guidebookId = filteredArray.pop();
            const guidebook = state.user.validations.find((g) => g.id == guidebookId);

            return getUrl(PWAUrls.TOPO_VALIDATION, {lng: locale, slug: guidebook.slug});
        }

        return null;
    }

    const clearTickingsReactQueryCache = () => {
        clearRoutesReactQueryCache();
        const urls = [
            ApiUrls.TICKINGS,
        ];

        ApiLanguages.forEach((apiLanguage) => {
            urls.forEach((url) => {
                queryClient.invalidateQueries({queryKey: [url.replace('{lng}', apiLanguage)]});
            });
        });
    }

    const clearRoutesReactQueryCache = () => {
        const urls = [
            ApiUrls.SEARCH,
            ApiUrls.SEARCH_MY_ROUTE,
            ApiUrls.SECTOR_AUTOCOMPLETE,
            ApiUrls.MULTIPITCH_AUTOCOMPLETE,
        ];

        ApiLanguages.forEach((apiLanguage) => {
            urls.forEach((url) => {
                queryClient.invalidateQueries({queryKey: [url.replace('{lng}', apiLanguage)]});
            });
        });
    }

    const clearReactQueryCache = () => {
        const urls = [
            ApiUrls.CUSTOMER.replace('{email}', state.user.email),
            ApiUrls.SEARCH,
            ApiUrls.SEARCH_MY_ROUTE,
            ApiUrls.SECTOR_AUTOCOMPLETE,
            ApiUrls.MULTIPITCH_AUTOCOMPLETE,
        ];

        ApiLanguages.forEach((apiLanguage) => {
            urls.forEach((url) => {
                queryClient.invalidateQueries({queryKey: [url.replace('{lng}', apiLanguage)]});
            });
        });
    }

    const getFlashMessages = (regionId = null) => {
        console.log(state);
        return state?.flashMessages;
    }

    const setFlashMessages = (data) => {
        dispatch({
            type: loginActions.SET_FLASH_MESSAGES,
            payload: data
        })
    }

    const getAckFlashMessageIds = () => {
        console.log(state);
        return state?.ackFlashMessageIds;
    }

    const addAckFlashMessageIds = (id) => {
        dispatch({
            type: loginActions.SET_ACK_FLASH_MESSAGE_IDS,
            payload: state.ackFlashMessageIds.push(id),
        })
    }

    return {
        ...state,
        login,
        logout,
        updateUser,
        isRouteStored,
        cartExists,
        setCart,
        getCart,
        isInCart,
        hasShippedItem,
        getCartLength,
        getUniqUserKey,
        clearTickingsReactQueryCache,
        clearReactQueryCache,
        clearRoutesReactQueryCache,
        hasParentGuidebookInCart,
        hasValidatedGuidebook,
        getValidationLink,
        getFlashMessages,
        setFlashMessages,
        getAckFlashMessageIds,
        addAckFlashMessageIds,
    }
}

export { AuthProvider, useAuth }
