import * as React from 'react';
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";

const OmegarocButton = styled(Button)(({ theme }) => ({
        "&.buttonTooltip": {
        padding: '0px',
        textTransform: 'initial',
        minWidth: 'auto',
        fontSize: "inherit",
        lineHeight: "inherit",
        letterSpacing: "inherit",
    },
    "&.tertiary":{
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.tertiary.contrastText,
        "&:hover":{
            backgroundColor: theme.palette.tertiary.dark,
        },
    },
    "&.tertiary.MuiButton-text":{
        backgroundColor: "initial",
        color: theme.palette.tertiary.main,
        "&:hover":{
            color: theme.palette.tertiary.dark,
        },
    },
    "&.quaternary":{
        backgroundColor: theme.palette.quaternary.main,
        color: theme.palette.quaternary.contrastText,
        "&:hover":{
            backgroundColor: theme.palette.quaternary.dark,
        },
    },
    "&.quaternary.MuiButton-text":{
        backgroundColor: "initial",
        color: theme.palette.quaternary.main,
        "&:hover":{
            color: theme.palette.quaternary.dark,
        },
    },
    "&.gray":{
        backgroundColor: theme.palette.gray.main,
        color: theme.palette.gray.contrastText,
        "&:hover":{
            backgroundColor: theme.palette.gray.dark,
        },
    },
    "&.gray.MuiButton-text":{
        backgroundColor: "initial",
        color: theme.palette.gray.main,
        "&:hover":{
            color: theme.palette.gray.dark,
        },
    },
}));

const allowedColors = ['primary', 'secondary', 'tertiary', 'success', 'info', 'warning'];
export const MuiButton = (props) => {

    //const { className, color, ...rest } = props;
    const { className, color } = props;
   // console.log(props, (allowedColors.includes(color) ? '' : color) + ` ${className} `);
    return <OmegarocButton
                    {...props}
                   color={allowedColors.includes(color) ? color : "primary"}
                   className={(allowedColors.includes(color) ? '' : color) + ' ' + (className ? className : '')}
    />
};