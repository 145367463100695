import {useQuery} from '@tanstack/react-query'
import {ApiUrls} from "../config/urls";
import axios from "axios";

const fetchUser = (queryKey) => {
    let path = typeof queryKey === 'string' ? queryKey : queryKey[0];
    return axios.get(`${path}`).then((res) => {
        let data = res.data?.data ? res.data.data : res.data;

        if (data?.user?.customerRoutes) {
            const routes = Object.keys(data.user.customerRoutes).map((k) => data.user.customerRoutes[k]);
            if (Array.isArray(routes)) {
                data.user.customerRoutes = routes;
            }
        } else {
            data.user.customerRoutes = [];
        }

        return data;
    })
}

export function useUser(email, language, forceRefresh) {
    const url =  ApiUrls.CUSTOMER.replace('{email}', email).replace('{lng}', language);

    let options = {
        queryKey: [url],
        queryFn: ({queryKey}) => fetchUser(queryKey),
        refetchOnWindowFocus: "always",
        cacheTime: Infinity,
        staleTime: import.meta.env.VITE_REACT_QUERY_USER_STALE_TIME*1,
        enabled: Boolean(email)
    };

    if (forceRefresh) {
        options.staleTime = 0;
    }

    return useQuery(options);
}