import React, {useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {useRouteUnlock} from "../../hooks/useRoute";

/**
 * Composant de SaveRouteComponent
 */
const SaveRouteComponent = ({i18n, saveRoute, user, setStatus}) => {
    const { status, data } = useRouteUnlock(i18n.language, saveRoute.type, saveRoute.route.slug, true, user);

    useEffect(() => {
        if (setStatus !== undefined) {
            if (status !== "success" && data) {
                setStatus("success");
            } else {
                setStatus(status);
            }
        }
    }, [status]);

    return (<></>);
};

export default withTranslation()(SaveRouteComponent);