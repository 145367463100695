import axios from 'axios'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {ApiLanguages, ApiUrls} from "../config/urls";
import Constants from "../config/constants";

export default function useStoredRouteUpdate(language, type) {
     const queryClient = useQueryClient();

     let routeUrl = null;
     if (type === Constants.TypeTopo.FALAISE) {
         routeUrl = ApiUrls.SECTOR_ROUTE_UNLOCK;
     } else {
         routeUrl = ApiUrls.MULTIPITCH_ROUTE_UNLOCK;
     }

    return useMutation({
        networkMode: 'always',
        mutationFn: (values) => axios.post(ApiUrls.STORE_ROUTE_UPDATE.replace('{lng}', language), values)
            .then((res) => {
                if (res.data.success === true) {
                    return res.data.data;
                } else {
                    return res.data;
                }
            })
        ,
        onError: (error, _updateStoreRoute, rollback) => {
            if (rollback) rollback()
        },
        onSuccess: async (data) => {
            for (const apiLanguage of ApiLanguages) {
                if (data?.burst_cache) {
                    const routeQueryKey = [routeUrl.replace('{lng}', apiLanguage), data.burst_cache];
                    await queryClient.invalidateQueries({queryKey: routeQueryKey});

                    delete data.burst_cache;
                }
                const customerUrl = ApiUrls.CUSTOMER.replace('{email}', data?.user?.email).replace('{lng}', language);
                console.log(data, [customerUrl, {email: data?.user?.email, language: language}]);
                await queryClient.invalidateQueries({queryKey:[customerUrl]});

            }
            const url = ApiUrls.CUSTOMER.replace('{email}', data?.user?.email).replace('{lng}', language);
            queryClient.setQueryData([url], data);
        }
    });
}
