import axios from 'axios'
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {ApiLanguages, ApiUrls} from "../config/urls";
import {useAuth} from "../context/auth-context";

export const fetchFlashMessages = (queryKey) => {
    let path = typeof queryKey === 'string' ? queryKey : queryKey[0];

    return axios.get(`${path}`).then((res) => res.data?.data ? res.data.data : res.data)
}

export function useFlashMessages(language, isAuthenticated) {
    const queryKey = [ApiUrls.GET_FLASH_MESSAGES.replace('{lng}', language)];

    return useQuery({
        queryKey: queryKey,
        queryFn: ({queryKey}) => fetchFlashMessages(queryKey),
        enabled: isAuthenticated
    })
}

export function useFlashMessageAck(language) {
    const queryClient = useQueryClient();

    return useMutation({
            networkMode: 'always',
            mutationFn:(flashMessageId) => axios.post(ApiUrls.ACK_FLASH_MESSAGES.replace('{lng}', language).replace('{id}', flashMessageId), [])
                .then((res) => {
                    if (res.data.success === true) {
                        ApiLanguages.forEach((apiLanguage) => {
                            queryClient.invalidateQueries({queryKey: [ApiUrls.GET_FLASH_MESSAGES.replace('{lng}', apiLanguage)]});
                        });
                        // queryClient.setQueryData(flashMessageListQueryKey, (prev) => {
                        //     console.log(res.data.data);
                        //     return  res.data.data;
                        // });
                    } else {
                        return res.data;
                    }
                })
            ,

            onError: (error, _newFlashMessage, rollback) => {
                // console.error(error);
                // if (typeof error.response.data.error !== "undefined") {
                //     setServerErrors(error.response.data.error);
                // }

                if (rollback) rollback()
            },
            onSuccess: async (updateFlashMessage) => {
                // for (const apiLanguage of ApiLanguages) {
                //     await queryClient.invalidateQueries({queryKey: [flashMessageListQueryKey]})
                // }
            },
        }
    )
}
