import Actions from "../config/actions";

const initialState = {
    guidebookQuery: [],
    queries: [],
    viewports: [],
};

/**
 * @param state
 * @param action
 * @returns {*}
 */
const search = (state = initialState, action) => {
    let data = null;

    switch (action.type) {
        case Actions.SET_SEARCH_GUIDEBOOK_QUERY:
            data = state;
            data.guidebookQuery = action.guidebookQuery;

            return data;
        case Actions.SET_SEARCH_QUERY:
            data = state;
            data.queries[action.screen] = action.query;

            return data;

        case Actions.SET_MAP_VIEWPORT:
            data = state;
            if (!Array.isArray(data.viewports)) {
                data.viewports = [];
            }
            console.log('setViewport', action.viewport);
            data.viewports[action.screen] = action.viewport;

            return data;

        default:
            return state;
    }
};

export default search;