import NetworkDetector from "./utils/NetworkDetector";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link, Route, Routes} from "react-router-dom";
import LocationChangeListener from "./components/LocationChangeListener";
import {ToastContainer} from "react-toastify";
import FullscreenSpinnerComponent from "./components/FullscreenSpinnerComponent";
import Navbar from "./components/Navbar";
import Constants from "./config/constants";
import ErrorHandler from "./components/ErrorHandler";
import React, {lazy, Suspense} from "react";
import {BottomNavigation, BottomNavigationAction, Box} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IosPrompt from "./components/Prompt/IosPrompt";
import UpdatePWAComponent from "./components/Prompt/UpdatePWAComponent";
import {showFullscreenSpinner} from "./actions";
import SuspenseLoader from "./components/SuspenseLoader";
import NoMatch from "./components/NoMatch";
import {getUrl, PWAUrls, PWAUrlsParams} from "./config/urls";

const UserRouter = lazy(() => import('./UserRouter'));
const TickingRouter = lazy(() => import('./TickingRouter'));
const RouteRouter = lazy(() => import('./RouteRouter'));

const bottomNavigations = [
    {label: "App.menu.myroute", icon: "star", link: PWAUrls.MY_ROUTES},
    {label: "App.menu.search", icon: "search", link: PWAUrls.SEARCH},
    {label: "App.menu.guidebook", icon: "book", link: PWAUrls.TOPO_LIST},
    {label: "App.menu.cart", icon: "cart-plus", link: PWAUrls.USER_CART},
];

const AppRouter =({i18n, t, clickInstallMessage, showInstallMessage, bottomNavigationItems, showFullscreenSpinner}) => {
    return (<LocationChangeListener>
            <ToastContainer />
            <div className={"app"}>
                {showFullscreenSpinner && <FullscreenSpinnerComponent/>}

                <Navbar brand={Constants.BRAND}/>

                <ErrorHandler>
                    <div className={"flexGrowScrollOverflow noScrollbar"}>
                        {/*<UpdatePWAComponent />*/}

                        <Routes>
                            <Route path={'/' + PWAUrlsParams.account.en + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><UserRouter authenticateRoute={false} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.account.fr + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><UserRouter authenticateRoute={false} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.myaccount.en + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><UserRouter /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.myaccount.fr + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><UserRouter /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.ticking.fr + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><TickingRouter/></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.ticking.en + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><TickingRouter/></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.routes.en + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.ROUTE} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.routes.fr + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.ROUTE} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.search.en + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.SEARCH} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.search.fr + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.SEARCH} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.search.en + '/'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.SEARCH} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.search.fr + '/'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.SEARCH} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.guidebooks.en + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.TOPOS} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.guidebooks.fr + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.TOPOS} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.guidebook.en + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.TOPO} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.guidebook.fr + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.TOPO} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.presentation.fr + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.PRESENTATION} /></Suspense>}/>
                            <Route path={'/' + PWAUrlsParams.presentation.en + '/*'} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter from={Constants.ROUTER.PRESENTATION} /></Suspense>}/>

                            {/*<Route path={optionalLocales + "(/routes|/ticking|/presentation)"} element={<Suspense fallback={<SuspenseLoader/>}><RouteRouter/></Suspense>}*/}
                            {/*/>*/}

                            <Route path={'*'} element={<NoMatch/>}/>
                        </Routes>

                    </div>

                </ErrorHandler>
                {bottomNavigationItems && <div>
                    <Box display={{ xs: 'none', lg: 'block' }} className={"desktopFooter"}>
                        <div className="row bg-primary ml-0 mr-0 justify-content-center">
                            {bottomNavigations.map((bottomNavigation, index) => {
                                return (
                                    <div className="col-auto mt-1" key={`desktopLink${index}`}>
                                        <Link to={getUrl(bottomNavigation.link, {lng: i18n.language})}>
                                            <FontAwesomeIcon icon={["fas", bottomNavigation.icon]} size="sm" />&nbsp;{t(bottomNavigation.label)}
                                        </Link>
                                    </div>
                                );
                            })}
                            <div className="col-12 text-center">
                                <small><Link to={getUrl(PWAUrls.HOME, {lng: i18n.language})}>{t("App.footer.label")}</Link></small>
                            </div>
                        </div>
                    </Box>
                    <Box display={{ xs: 'block', lg: 'none' }}>
                        <footer  style={{height: '56px'}}/>
                        <BottomNavigation value={"search"}
                                          showLabels
                                          className={"stickToBottom"}
                        >
                            {bottomNavigations.map((bottomNavigation, index) => {
                                return (
                                    <BottomNavigationAction
                                        key={index}
                                        component={Link}
                                        to={getUrl(bottomNavigation.link, {lng: i18n.language})}
                                        label={t(bottomNavigation.label)}
                                        value={bottomNavigation.link}
                                        icon={<FontAwesomeIcon icon={["fas", bottomNavigation.icon]} size="sm" />}
                                    />
                                );
                            })}
                        </BottomNavigation>
                    </Box>
                </div>}

                {/*{!window.isPwa && <>*/}
                    {showInstallMessage && !clickInstallMessage && <IosPrompt />}

                    <UpdatePWAComponent />
                {/*</>}*/}
            </div>
        </LocationChangeListener>);
}

const mapDispatchToProps = dispatch => {
    return {
        showFullscreenSpinnerAction: () => {
            dispatch(showFullscreenSpinner())
        },
    }
};

const mapStateToProps = (state) => {
    return {
        showFullscreenSpinner: (typeof state.common.showFullscreenSpinner !== 'undefined' ? state.common.showFullscreenSpinner : false),
        bottomNavigationItems: (typeof state.common.bottomNavigationItems !== 'undefined' ? state.common.bottomNavigationItems : false),
        showInstallMessage: (typeof state.common.showInstallMessage !== 'undefined' ? state.common.showInstallMessage : false),
        clickInstallMessage: (typeof state.common.clickInstallMessage !== 'undefined' ? state.common.clickInstallMessage : false),
    }
};

export default NetworkDetector(
    compose(
        withTranslation(),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(AppRouter)
);
