import React, {useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {compose} from 'redux';
import {setGlobalError} from "../actions";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    Avatar,
    Box,
    Button,
    Container,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TextField
} from '@mui/material';
import logo from './../images/logo-back.png';
import {useIsFetching} from '@tanstack/react-query'
import {useAuth} from "../context/auth-context";
import SaveRouteListComponent from "./SaveRouteComponent/SaveRouteListComponent";
import SaveRouteComponent from "./SaveRouteComponent/SaveRouteComponent";
import Constants from "../config/constants";
import SaveCliffComponent from "./SaveRouteComponent/SaveCliffComponent";
import BookmarkComponent from "./SaveRouteComponent/BookmarkComponent";
import {useUser} from "../hooks/useUser";
import {styled} from "@mui/material/styles";
import {getUrl, PWAUrls} from "../config/urls";
import FlashMessages from "./User/FlashMessages/FlashMessages";

const MyAppBar = styled(AppBar)(({ theme }) => ({
    '& a.MuiIconButton-root:hover': {
        color: '#fff',
    },
}));

const Navbar =({i18n, t, title, goBackUrl, showMenuNavBar, isOnline}) => {
    const isFetching = useIsFetching();
    const auth = useAuth();
    const { status: statusUser, data: user, error: errorUser } = useUser(auth.user?.email, i18n.language);

    useEffect(() => {
        if (user?.user) {
            auth.updateUser(user?.user);
        }
    }, [user]);

    const customerRoutes = user?.user?.customerRoutes ? user.user.customerRoutes : [];

    const history = useNavigate();

    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const [openFilterDrawer, setOpenFilterDrawer] = React.useState(false);
    const [openStoreRouteDrawer, setOpenStoreRouteDrawer] = React.useState(false);

    const toggleStoreRouteDrawer = (isOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenStoreRouteDrawer(isOpen);
    };

    const toggleDrawer = (isOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenFilterDrawer(isOpen);
    };

    if (!isOnline && !document.body.classList.contains("block-pull-to-refresh")) {
        document.body.classList.add('block-pull-to-refresh');
    }
    if (isOnline && document.body.classList.contains("block-pull-to-refresh")) {
        document.body.classList.remove('block-pull-to-refresh');
    }

    /**
     * Fonction de retour en arrière
     */
    const onClickGoBack = () => {
        if (typeof goBackUrl !== "undefined") {
            history(goBackUrl);
            return;
        }

        history.goBack();
    };

    const [searchFieldValue, setSearchFieldValue] = React.useState('');

    const submitSearch = () => {
        setOpenFilterDrawer(false);
        //history(searchFieldValue === ""  ? "/search" : "/search/" + searchFieldValue);
    }

    const externalLinks = [
        {
            label: 'Navbar.Facebook',
            urlfr: 'https://www.facebook.com/profile.php?id=100063118337120',
            urlen: 'https://www.facebook.com/profile.php?id=100063118337120'
        },
        {
            label: 'Navbar.Omegaroc',
            urlfr: 'https://omegaroc.com/omegaroc/',
            urlen: 'https://omegaroc.com/en/omega-roc-the-company/'
        },
        {label: 'Navbar.EthicalGuidebook',
            urlfr: 'https://omegaroc.com/topos-ethiques/',
            urlen: 'https://omegaroc.com/en/ethical-climbing-guidebooks/',
        },
        {label: 'Navbar.userBook',
            urlfr: 'https://omegaroc.com/appli-mode-demploi/',
            urlen: 'https://omegaroc.com/en/app-user-guide/'
        },
        {label: 'Navbar.Aboutus',
            urlfr: 'https://omegaroc.com/a-propos-appli/',
            urlen: 'https://omegaroc.com/en/about-the-app/'
        },
    ];

    const updateLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setAnchorElMenu(null);
    }

    const getI18nPathname = (locale) => {
        const prevLocale = locale === 'en' ? 'fr' : 'en';
        if (window.location.pathname.indexOf('/' + locale + '/') === 0) {
            return window.location.pathname;
        }

        if (window.location.pathname.indexOf('/' + prevLocale + '/') === 0) {
            return window.location.pathname.replace('/' + prevLocale + '/', '/' + locale + '/');
        }

        return  '/' + locale + window.location.pathname;
    }

    
    const mainMenuDrawer = () => (
        <div
            role="presentation"
            className={"mainMenuDrawer"}
        >
            <Box display="flex" p={2} pb={0} alignItems="center">
                <Box>
                    <Link to={getUrl(PWAUrls.HOME, {lng: i18n.language})} onClick={toggleDrawer(false)}>
                        <img src={logo} alt={"logo"} />
                    </Link>
                </Box>
                <Box flexGrow={1} px={1}>
                    <Link to={getUrl(PWAUrls.HOME, {lng: i18n.language})} onClick={toggleDrawer(false)}>
                        <Typography variant="h6">{t("Omegaroc.logo")}</Typography>
                    </Link>
                </Box>
                <Box pr={1}>
                    <Button color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => {setAnchorElMenu(event.currentTarget);}}>
                        {t("App.common." + i18n.language)}
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorElMenu}
                        keepMounted
                        open={Boolean(anchorElMenu)}
                        onClose={() => setAnchorElMenu(null)}
                    >
                        <MenuItem onClick={() => updateLanguage('en')} selected={i18n.language === "en"} component={Link} to={getI18nPathname('en')}>
                            <Typography>{t("App.common.en")}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => updateLanguage('fr')} selected={i18n.language === "fr"}  component={Link} to={getI18nPathname('fr')}>
                            <Typography>{t("App.common.fr")}</Typography>
                        </MenuItem>
                    </Menu>

                    {/*<NativeSelect*/}
                    {/*    value={i18n.language}*/}
                    {/*    onChange={handleLanguageChange}*/}
                    {/*>*/}
                    {/*    <option value='fr'>{t("App.common.fr")}</option>*/}
                    {/*    <option value='en'>{t("App.common.en")}</option>*/}
                    {/*</NativeSelect>*/}
                </Box>
                <Box>
                    <IconButton aria-label={t("Navbar.close")}
                                onClick={toggleDrawer(false)}>
                        <FontAwesomeIcon icon={["fas", "times"]}  className="primary" />
                    </IconButton>
                </Box>
            </Box>

            <List component="nav" aria-label="main link" py={0}>
                <ListItem>
                    <Box display="flex" p={0} alignItems="center">
                        <Box flexGrow={1} pr={1}>
                            <TextField
                                id="outlined-basic"
                                label={t("Navbar.searchLabel")}
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                value={searchFieldValue}
                                onChange={(event) => {setSearchFieldValue(event.target.value);}}
                                onKeyDown={(event) => {
                                    if(event.key === "Enter"){
                                        submitSearch();
                                    }
                                }}
                            />
                        </Box>
                        <Box>
                            <IconButton aria-label={t("Navbar.search")}
                                        size="small"
                                        onClick={submitSearch}>
                                <FontAwesomeIcon icon={["fas", "search"]}  className="primary" />
                            </IconButton>
                        </Box>
                    </Box>
                </ListItem>

                <ListItem component={Link} to={getUrl(PWAUrls.TOPO_LIST, {lng: i18n.language})} onClick={toggleDrawer(false)}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={["fas", "book"]}  className="primary" />
                    </ListItemIcon>
                    <ListItemText primary={t("Navbar.guidebooks")} />
                </ListItem>
                <ListItem component={Link} to={getUrl(PWAUrls.USER_CART, {lng: i18n.language})} onClick={toggleDrawer(false)}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={["fas", "cart-plus"]}  className="primary" />
                    </ListItemIcon>
                    <ListItemText primary={t("Navbar.cart") + (auth.cartExists() && auth.getCartLength() ? " (" + auth.getCartLength() + ")" : "")} />
                </ListItem>
                {externalLinks && externalLinks.length > 0 && <>
                    <hr className="mt-1 mb-0 mx-3"/>
                    <List component="nav" aria-label="secondary link" py={0}>
                        {externalLinks.map((externalLink, index) => <ListItem key={index}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={["fas", "external-link-alt"]}  className="primary" />
                            </ListItemIcon>
                            <a href={externalLink['url' + i18n.language]} target="_blank" rel="noreferrer" onClick={toggleDrawer(false)}>
                                <ListItemText primary={t(externalLink.label)} />
                            </a>
                        </ListItem>)}
                    </List>
                </>}
            </List>
        </div>
    );

    const storeRouteDrawerLabel = auth.isAuthenticated
        ? t("Navbar.saveRoute.label") + " (" + customerRoutes.length + "/" + import.meta.env.VITE_MAX_STORAGE + ")"
        : t("Navbar.saveRoute.label")
    ;

    const storeRouteDrawer = () => (
        <div
            role="presentation"
            className={"mainMenuDrawer"}
        >
            <Box className="text-right">
                <IconButton aria-label={t("Navbar.close")}
                            onClick={toggleStoreRouteDrawer(false)}>
                    <FontAwesomeIcon icon={["fas", "times"]}  className="primary" />
                </IconButton>
            </Box>
            <Box pb={1} style={{fontSize: '14px'}}>
                <BookmarkComponent setOpenStoreRouteDrawer={setOpenStoreRouteDrawer}/>
            </Box>
            <Box pl={2}>
                {storeRouteDrawerLabel}
            </Box>
            <List component="nav" aria-label="main link" py={0}>
                <SaveRouteListComponent user={user} customerRoutes={customerRoutes} onClose={setOpenStoreRouteDrawer} />
            </List>
        </div>
    )

    return (<>
            <MyAppBar position="fixed">
                <Container maxWidth="lg">
                    <Toolbar style={{paddingLeft: 0, paddingRight: 0}}>
                        <Box display="flex" alignItems="center" className={'w-100'}>
                            <Box>
                                {showMenuNavBar &&
                                <>
                                    <IconButton edge="start"
                                                color="inherit"
                                                aria-label={t("Navbar.menu")}
                                                onClick={toggleDrawer(true)}>
                                        <FontAwesomeIcon icon={["fas", "bars"]} />
                                    </IconButton>
                                    <Drawer anchor={'left'} open={openFilterDrawer} onClose={toggleDrawer(false)} width="90%">
                                        {mainMenuDrawer()}
                                    </Drawer>
                                </>
                                }
                                {(!showMenuNavBar && goBackUrl) &&
                                <IconButton edge="start"
                                       color="inherit"
                                       aria-label={t("Navbar.back")}
                                       onClick={onClickGoBack}>
                                    <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                                </IconButton>
                                }
                            </Box>
                            <Box flexGrow={1}>
                                <Typography variant="h6" className={"flexGrow"}>
                                    {title}
                                </Typography>
                            </Box>
                            {!isOnline &&
                            <Box>
                                <IconButton className="fa-stack fa-2x">
                                    <FontAwesomeIcon style={{color: '#FFF', fontSize: '0.9rem', left: '0', position: 'absolute', 'textAlign': 'center', width: '100%',}}  icon={["fas", "wifi"]}  />
                                    <FontAwesomeIcon style={{color: 'tomato', fontSize: '1.6rem', left: '0', position: 'absolute', 'textAlign': 'center', width: '100%',}} icon={["fas", "ban"]} />
                                </IconButton>
                            </Box>
                            }


                            {isOnline && isFetching > 0 &&
                            <Box>
                                <FontAwesomeIcon style={{color: '#FFF',fontSize: "1.5rem", height: "20px"}} icon={["fas", "spinner"]} spin />
                            </Box>
                            }

                            {auth.isAuthenticated && <Box>
                                <IconButton
                                    aria-label={t("Navbar.saveRoute.label")}
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={() => {setOpenStoreRouteDrawer(true);}}
                                    color="inherit">
                                    <FontAwesomeIcon icon={["fas", "save"]} />
                                </IconButton>
                                <Drawer anchor={'right'} open={openStoreRouteDrawer} onClose={toggleStoreRouteDrawer(false)} width="90%">
                                    {storeRouteDrawer()}
                                </Drawer>
                            </Box>}

                            {(!auth.isAuthenticated || !auth?.user?.fullName) &&
                            <Box>
                                <IconButton
                                    component={Link}
                                    to={getUrl(PWAUrls.USER_LOGIN, {lng: i18n.language})}
                                    aria-label={t("Navbar.account")}
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <FontAwesomeIcon icon={["fas", "user"]} />
                                </IconButton>
                            </Box>
                            }
                            {auth.isAuthenticated && auth.user &&
                            <Box>
                                {auth.user.fullName &&
                                    <Avatar component={Link}
                                            to={getUrl(PWAUrls.USER_PROFILE, {lng: i18n.language})}
                                            aria-label={t("Navbar.account")}
                                            alt={auth.user.fullName}
                                            style={{backgroundColor: '#FFF', color: '#132240', width: '28px', height: '28px'}}
                                    >
                                        {auth.user.fullName.substring(0,1).toUpperCase()}
                                    </Avatar>}
                            </Box>
                            }
                        </Box>
                    </Toolbar>
                    {/*Non graphical components: they will fetch data store data if needed*/}
                    {auth.isAuthenticated && customerRoutes.length > 0 && user?.user && <>
                        {customerRoutes.map((saveRoute) => {
                            if (saveRoute.type === Constants.TypeTopo.FALAISE) {
                                return (<React.Fragment key={"save-" + saveRoute.route.id}>
                                    <SaveCliffComponent slug={saveRoute.route?.cliff.slug} user={user.user} />
                                    <SaveRouteComponent saveRoute={saveRoute} user={user.user} key={"saveroute-" + saveRoute.route.id}/>
                                </React.Fragment>)
                            }
                            if (saveRoute.type === Constants.TypeTopo.GRANDEVOIE) {
                                return (<SaveRouteComponent saveRoute={saveRoute} user={user.user} key={"saveroute-" + saveRoute.route.id}/>);
                            }
                        })}
                    </>}
                    {auth.isAuthenticated && <FlashMessages />}
                </Container>
            </MyAppBar>
            <Toolbar/>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        title: state.navbar.title,
        goBackUrl: state.navbar.goBackUrl,
        routeCount: state.navbar.savedRoute.length,
        savedRoute: state.navbar.savedRoute,
        errorOccured: state.common.errorOccured,
        showMenuNavBar: state.navbar.showMenuNavBar,
        isOnline: state.common.isOnline,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setGlobalError: (hasError) => {
            dispatch(setGlobalError(hasError))
        },
    }
};

export default
    compose(
        withTranslation(),
        connect(
            mapStateToProps,
            mapDispatchToProps
        ))(Navbar);