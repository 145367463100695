import React from 'react';
import {withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// function SimpleDialog(props) {
//     const { onClose, selectedValue, open, t, isInstalled } = props;
//
//     const handleClose = () => {
//         onClose(selectedValue);
//     };
//
//     return (
//         <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
//             <DialogTitle id="simple-dialog-title">{t('UpdatePWAComponent.help.title')}</DialogTitle>
//             <DialogContent>
//                 <p><MuiButton variant="text"
//                               onClick={() => {window.open('https://preprod-app.omegaroc.com', '_blank', '');}}>
//                     {t('UpdatePWAComponent.help.open-pwa')}
//                 </MuiButton></p>
//                 <p>{t('UpdatePWAComponent.help.description')}</p>
//
//                 {!isInstalled && <p>{t('UpdatePWAComponent.help.install-pwa')}</p>}
//                 <p>{t('UpdatePWAComponent.help.update-navigator')}</p>
//             </DialogContent>
//         </Dialog>
//     );
// }

const UpdatePWAComponent = ({t}) => {
    // const [showHelp, setShowHelp] = useState(false);
    // const [isInstalled, setIsInstalled] = useState(false);
    //
    // const getRelatedApps = async () => {
    //     const relatedApps = await navigator.getInstalledRelatedApps();
    //
    //     relatedApps.find((app) => {
    //         setIsInstalled(true);
    //         return true;
    //     });
    // }
    // useEffect(() => {
    //     getRelatedApps();
    //
    //
    //
    // }, []);

    // const handleClose = () => {
    //     setShowHelp(false);
    // };

    return (
        <div id={'update-pwa-block'}>
            <div id={'update-banner'} onClick={() => window.location.reload()}>
                <p>
                    <FontAwesomeIcon icon={['fas', 'sync-alt']} className="mr-1"/>
                    {t('UpdatePWAComponent.updateText')}
                </p>
                {/*{!isInstalled && <MuiButton variant="text" onClick={() => setShowHelp(true)}>*/}
                {/*    {t('UpdatePWAComponent.help.install')}*/}
                {/*</MuiButton>}*/}
            </div>
            {/*<SimpleDialog open={showHelp} onClose={handleClose} t={t} isInstalled={isInstalled}/>*/}
        </div>
    );
}

export default withTranslation()(UpdatePWAComponent);