import {combineReducers} from 'redux'
import navbar from "./navbar";
import common from "./common";
import search from "./search";
import shop from "./shop";

export default combineReducers({
    // topos,
    navbar,
    common,
    search,
    shop,
})