import React from 'react';
import {withTranslation} from 'react-i18next';

import AddToHome from '../../images/add2home.svg';
import Share from '../../images/share.svg';
import {toggleInstallMessage} from "../../actions";
import {compose} from "redux";
import {connect} from "react-redux";
import SVG from 'react-inlinesvg';

const iOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
const IosPrompt = ({t, toggleInstallMessage}) => {
    if (!iOS() || isInStandaloneMode()) {
        return;
    }

    return (
        <div className="ios-prompt" onClick={() => toggleInstallMessage(false, true)}>
            <div className={'row'}>
                <div className={'col-auto addToHome'}>
                    <SVG
                        className={'shareAddToScreen'}
                        src={AddToHome}
                    />
                </div>
                <div className={'col'}>
                    <p style={{lineHeight: '1.3rem'}}>
                        {t('App.common.promptInstall1')}
                        <span
                            style={{
                                display: 'inline-block',
                                marginBottom: '-4px',
                                height: '20px',
                                width: 'auto'
                            }}>
                        <SVG className={'shareSVG'} src={Share}/>
                    </span>
                        {t('App.common.promptInstall2')}
                    </p>
                </div>
            </div>
            <div className={'arrow-down'}/>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        toggleInstallMessage: (toggle, click = false) => {
            dispatch(toggleInstallMessage(toggle, click))
        },
    }
}

export default
    compose(
        withTranslation(),
        connect(
            null,
            mapDispatchToProps
        ))(IosPrompt);