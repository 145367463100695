import React from 'react';
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import AlertComponent from "./AlertComponent";
import {getUrl, PWAUrls} from "../config/urls";

const NoMatch = ({i18n, t}) => {
    return (<AlertComponent
        title={t("NoMatch.title")}
        message={t("NoMatch.description")}
        severity={'warning'}
        reload={false}
        backgroundIcon={'ban'}
        ctaLabel={t("NoMatch.back-home")}
        backButtonShow={true}
        ctaIcon={'house'}
        ctaLinkTo={getUrl(PWAUrls.HOME, {lng: i18n.language})}
    />);
}


export default compose(
    withTranslation()
)(NoMatch);
