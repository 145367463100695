import React from 'react';
import {Link} from "react-router-dom";
// import {postUrl} from "../utils/helpers";
import {ApiUrls, getUrl, PWAUrls} from "../config/urls";
import {setGlobalError} from "../actions";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import AlertComponent from "./AlertComponent";

class ErrorHandler extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errorOccurred: false,
            isDev: true,
            error: null,
        }
    }

    componentDidCatch(error, info) {
        // this.setState({
        //     errorOccurred: true
        // })
        this.props.setGlobalError(true);
        console.log('ErrorHandler componentDidCatch');
        //this.logError(error, info);
        // console.log(info);
        // logErrorToMyService(error, info)
    }

    logError(error, info) {
        try {
            let trace = error.toString();

            if (typeof error.stack !== 'undefined') {
                trace = error.stack;
            } else {
                if (info && typeof info.componentStack !== 'undefined') {
                    trace += ' ' + info.componentStack;
                }
            }

            this.setState({error:error});

            // postUrl(ApiUrls.ERROR, {
            //     'trace': trace,
            //     'url': window.location.href,
            //     'state': localStorage.getItem('state'),
            // }).then(r => {
            //     // console.log(r);
            // });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const {t, i18n} = this.props;

        return this.props.errorOccured ? (
            <div className={'appError container'}>
                <h1 style={{fontSize: '1.5rem'}}>{t("ErrorHandler.message")}</h1>
                {this.state.isDev && this.state.error !== null &&
                <p>{this.state.error.toString()}</p>
                }
                <span onClick={
                    () => {
                        this.props.setGlobalError(false);
                    }
                }>
                    {t("ErrorHandler.reload")}
                </span>
                <Link to={getUrl(PWAUrls.HOME, {lng: i18n.language})} onClick={() => {
                    this.props.setGlobalError(false);
                }}>
                    <p className={'mt-3'}>{t("ErrorHandler.home")}</p>
                </Link>
            </div>
        ) : this.props.children
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        errorOccured: typeof state.common.errorOccured !== 'undefined' ? state.common.errorOccured : false,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setGlobalError: (hasError) => {
            dispatch(setGlobalError(hasError))
        },
    }
}

export default
    compose(
        withTranslation(),
        connect(
            mapStateToProps,
            mapDispatchToProps
        ))(ErrorHandler);