import * as React from 'react';
import axios from "axios";
import {ApiUrls} from "../config/urls";
import {addDays, format, isAfter} from "date-fns";

const refererActions = {
    SET: "SET",
};
const RefererContext = React.createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case refererActions.SET:
            localStorage.setItem(storageConstants.referer, JSON.stringify(action.payload.referer));
            localStorage.setItem(storageConstants.date, JSON.stringify(action.payload.date));
            return {
                ...state,
                referer: action.payload.referer,
                date: action.payload.date,

            };
        default:
            return state;
    }
};

const storageConstants = {
    referer: 'REFERER',
    date: 'REFERER_DATE'
};

const getInitialState = () => {
    const initialState = {
        referer: null,
        date: null,
    };

    if (localStorage.getItem(storageConstants.referer) !== null
        && localStorage.getItem(storageConstants.referer) !== "undefined"
        && typeof localStorage.getItem(storageConstants.referer) !== "undefined") {
        initialState.referer = JSON.parse(localStorage.getItem(storageConstants.referer));
        initialState.date = JSON.parse(localStorage.getItem(storageConstants.date));
    }

    const params = (new URL(document.location)).searchParams;
    let referer = params.get("referer");
    if (referer) {
        initialState.referer = referer;
        initialState.date = new Date();

        axios.post(
            //TODO update language when error message are handled
            ApiUrls.REFERER.replace('{lng}', 'fr'),
            {referer: referer, date:  format(new Date(), 'yyyy-MM-dd hh:mm:ss') }
        )
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                }
            });
    }

    return initialState;
}

function RefererProvider({children}) {
    const [state, dispatch] = React.useReducer(reducer, getInitialState());

    return (
        <RefererContext.Provider value={{
            state,
            dispatch
        }}>
            {children}
        </RefererContext.Provider>
    )
}

const useReferer = () => {
    //const { i18n } = useTranslation();
    const { state, dispatch } = React.useContext(RefererContext);

    const setReferer = (data) => {
        dispatch({
            type: refererActions.SET,
            payload: data
        })
    }

    const getReferer = () => {
        if (!state?.referer
            || !state?.date) {
            return "";
        }

        if (state.date instanceof String) {
            return;
        }

        const isRefererValid = isAfter(addDays(state.date, 1), new Date());
        if (!isRefererValid) {
            return "";
        }

        return state.referer.slice(0, 254);
    }

    return {
        ...state,
        setReferer,
        getReferer
    }
}

export { RefererProvider, useReferer }
