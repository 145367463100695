import axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import Constants from "../config/constants";

const fetchLocale = (queryKey) => {
    let path = typeof queryKey === 'string' ? queryKey : queryKey[0];
    return axios.get(`${path}`).then((res) => res.data?.data ? res.data.data : res.data)
}

export function useLocale(url, options) {
    options.cacheTime = Infinity;
    options.queryKey = [url, Constants.SW_VERSION];
    options.queryFn = ({queryKey}) => fetchLocale(queryKey);

    return useQuery(options)
}