import React from 'react';
import {useLocation} from "react-router-dom";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const LocationChangeListener = ({ children }) => {
    const location = useLocation();
    const { trackPageView } = useMatomo();

    React.useEffect(() => {
        trackPageView({
            href: window.location.pathname,
        });
    }, [location]);

    return children;
}

export default LocationChangeListener;