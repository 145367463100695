import React, {useEffect, useState} from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles'
import themeOptions from './style/theme';
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import NetworkDetector from "./utils/NetworkDetector";
import {compose} from 'redux';
import {hideFullscreenSpinner, showFullscreenSpinner, toggleInstallMessage} from "./actions";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faCompass,
    faBug,
    faHouse,
    faArrowLeft,
    faArrowRight,
    faArrowsAltV,
    faBan,
    faBars,
    faBook,
    faBookmark,
    faCaretLeft,
    faCaretRight,
    faCartPlus,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCogs,
    faCompress,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExternalLinkAlt,
    faFilter,
    faFire,
    faGlobe,
    faHeart,
    faHome,
    faImage,
    faInfo,
    faInfoCircle,
    faLock,
    faLockOpen,
    faMinus,
    faMobileAlt,
    faPencilAlt,
    faPlus,
    faReceipt,
    faSave,
    faSearch,
    faShoppingCart,
    faSignInAlt,
    faSignOutAlt,
    faSortNumericDown,
    faSortNumericDownAlt,
    faSpinner,
    faStar,
    faSync,
    faSyncAlt,
    faTachometerAlt,
    faThumbsUp,
    faTimes,
    faTrash,
    faUnlock,
    faUser,
    faWifi,
    faEye, faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {enUS, frFR} from '@mui/material/locale';
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {useLocale} from "./hooks/useLocale";
import {ApiUrls, getUrl, PWAUrls} from "./config/urls";
import AppRouter from "./AppRouter";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-GB';

library.add(faEye, faEyeSlash, faCompass, faBug, faHouse, faExternalLinkAlt, faFire, faImage, faSignInAlt, faExclamationTriangle, faInfo, faSync, faStar,faGlobe, faCogs, faSortNumericDown, faSortNumericDownAlt, faPlus, faCheckCircle, faHome, faThumbsUp, faReceipt, faUnlock, faLock, faLockOpen, faSignOutAlt, faShoppingCart, faArrowsAltV, faArrowRight,faSyncAlt, faChevronUp, faBookmark, faCheck, faCaretRight, faCaretLeft, faCompress, faExpand, faInfoCircle, faTrash, faHeart, faPencilAlt, faArrowLeft, faWifi, faBars, faBan, faUser, faSave, faTachometerAlt, faMobileAlt, faExclamationCircle, faChevronDown, faChevronRight, faChevronLeft, faFilter, faTimes, faSearch, faBook, faCartPlus, faSpinner, faMinus);

const App =({i18n, hideFullscreenSpinnerAction}) => {
  const { trackPageView, enableLinkTracking } = useMatomo();
  const [canLoadLocale, setCanLoadLocale] = useState(false);

  const i18nLanguage = typeof i18n.language !== "undefined" ? i18n.language.toLowerCase() : "en";
  if (i18nLanguage.length > 2) {
    if (i18nLanguage.startsWith("en")) {
      i18n.changeLanguage("en");
    }

    if (i18nLanguage.startsWith("fr")) {
      i18n.changeLanguage("fr");
    }
  }
  if (!i18nLanguage.startsWith("fr") && !i18nLanguage.startsWith("en")) {
    i18n.changeLanguage("en");
  }

  enableLinkTracking();

  useEffect(() => {
    // Detects if device is on iOS
    // const isIos = () => {
    //     const userAgent = window.navigator.userAgent.toLowerCase();
    //     return /iphone|ipad|ipod/.test(userAgent);
    // };
    // // Detects if device is in standalone mode
    //const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    hideFullscreenSpinnerAction();

    trackPageView({});
    setTimeout(() => {
      setCanLoadLocale(true);
    }, 200);
  }, []);

  let locale = i18n.language === 'fr' ? frFR : enUS;
  let dayfnsLocale = locale === 'fr' ? fr : en;
  useEffect(() => {
    locale = i18n.language === 'fr' ? frFR : locale;
    dayfnsLocale = locale === 'fr' ? fr : en;
  }, [i18n]);

  const {data} = useLocale(ApiUrls.API_URL + '/' + i18n.language + '/locale', {enabled: canLoadLocale},);
  useEffect(() => {
    if (data) {
      i18n.addResourceBundle(i18n.language, "translation", data, true, true);
    }
  }, [data]);

  const theme = createTheme(themeOptions, locale);

  const redirectRoot = window.location.pathname === '/';
  const redirectLocale = window.location.pathname === '/en'
      || window.location.pathname === '/en/'
      || window.location.pathname === '/fr'
      || window.location.pathname === '/fr/'
  ;

  const redirectUri = !redirectLocale && !redirectRoot
      && window.location.pathname.indexOf('/en') !== 0
      && window.location.pathname.indexOf('/en/') !== 0
      && window.location.pathname.indexOf('/fr') !== 0
      && window.location.pathname.indexOf('/fr/') !== 0
  ;

  return (
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dayfnsLocale}>
          <BrowserRouter>
            <Routes>
              <Route path={'/fr/*'} element={<AppRouter />}/>
              <Route path={'/en/*'} element={<AppRouter />}/>
            </Routes>

            {/*redirectUri before other redirect*/}
            {redirectUri && <Navigate path={window.location.pathname} to={'/' + i18n.language + window.location.pathname}/>}
            {redirectRoot && <Navigate path={'/'} to={getUrl(PWAUrls.MY_ROUTES, {lng: i18n.language})}/>}
            {redirectLocale && <Navigate path={window.location.pathname} to={getUrl(PWAUrls.MY_ROUTES, {lng: i18n.language})}/>}

          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    toggleInstallMessage: (toggle, click = false) => {
      dispatch(toggleInstallMessage(toggle, click))
    },
    showFullscreenSpinnerAction: () => {
      dispatch(showFullscreenSpinner())
    },
    hideFullscreenSpinnerAction: () => {
      dispatch(hideFullscreenSpinner())
    },
  }
};

const mapStateToProps = (state) => {
  return {
    showFullscreenSpinner: (typeof state.common.showFullscreenSpinner !== 'undefined' ? state.common.showFullscreenSpinner : false),
    showMenuNavBar: (typeof state.navbar.showMenuNavBar !== 'undefined' ? state.navbar.showMenuNavBar : true),
    bottomNavigationItems: (typeof state.common.bottomNavigationItems !== 'undefined' ? state.common.bottomNavigationItems : false),
    language: (typeof state.navbar.language !== 'undefined' ? state.navbar.language : null),
    showInstallMessage: (typeof state.common.showInstallMessage !== 'undefined' ? state.common.showInstallMessage : false),
    clickInstallMessage: (typeof state.common.clickInstallMessage !== 'undefined' ? state.common.clickInstallMessage : false),
  }
};

export default NetworkDetector(
    compose(
        withTranslation(),
        connect(
            mapStateToProps,
            mapDispatchToProps
        )
    )(App)
);
