import axios from 'axios'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {ApiUrls} from "../config/urls";
import {getStoreRouteCacheTime} from "../utils/helpers";
import Constants from "../config/constants";

const fetchRoute = (queryKey, slug) => {
    let path = typeof queryKey === 'string' ? queryKey : queryKey[0];

    return axios.get(`${path}/${slug}`).then((res) => res.data?.data ? res.data.data : res.data)
}

const useRouteQuery = (language, slug, url, options) => {
    options.queryKey = [url, slug];
    options.queryFn =   ({queryKey}) => fetchRoute(queryKey, slug);
    return useQuery(options);
}

export function useRoute(language, type, slug, unlock, options = {}) {
    const queryClient = useQueryClient();
    const url = getRouteUrl(type, language);
    options.enabled = Boolean(url && slug) && unlock === false;
    options = {...queryClient.getDefaultOptions().queries, ...options, enabled: Boolean(url && slug && !unlock)}

    return useRouteQuery(language, slug, url, options);
}

export function hasAllSectorSaved(user, cliff) {
    if (!user?.customerRoutes
        || !Array.isArray(user.customerRoutes)
        || cliff?.sectorRoutes.length === 0) {
        return false;
    }

    let hasAllSector = true;
    cliff.sectorRoutes.forEach((sector) => {
        if (!sector.enabled) {
            return;
        }

        if (typeof (user.customerRoutes.find((el) => el.route.id === sector.id)) === "undefined") {
            hasAllSector = false;
            return false;
        }
    });

    return hasAllSector;
}

export function hasUserStoreRouteBySlug(user, slug) {
    if (!user?.customerRoutes || !Array.isArray(user.customerRoutes)) {
        return null;
    }

    return user.customerRoutes.find((r) => r.route.slug === slug);
}

export function useRouteUnlock(language, type, slug, unlock, user = {}) {
    const queryClient = useQueryClient();
    const url = getRouteUnlockUrl(type, language);
    let options = {...queryClient.getDefaultOptions().queries};
    //let hasStoreData = false;
    const storeRoute = hasUserStoreRouteBySlug(user, slug);

    if (storeRoute) {
        // if (typeof queryClient.getQueryData([url, slug]) !== "undefined"
        //     && !queryClient.getQueryCache().find([url, slug]).state.isInvalidated) {
        //     hasStoreData = true;
        // }

        const cacheTime = getStoreRouteCacheTime(storeRoute?.endValidationDate);
        if (cacheTime === -1) {
            options.refetchOnWindowFocus = false;
            options.cacheTime = Infinity;
            options.networkMode ='offlineFirst';
        }
        if (cacheTime > 0) {
            options.refetchOnWindowFocus = false;
            options.cacheTime = cacheTime;
            options.staleTime = cacheTime;
            options.networkMode ='offlineFirst';
        }
    }

    options.enabled = Boolean(url && slug && unlock && user?.email);
    return useRouteQuery(language, slug, url, options);
}

function getRouteUrl(type, language)
{
    if (type === Constants.TypeTopo.FALAISE) {
        return ApiUrls.SECTOR_ROUTE.replace('{lng}', language);
    }

    if (type === Constants.TypeTopo.GRANDEVOIE) {
        return ApiUrls.MULTIPITCH_ROUTE.replace('{lng}', language);
    }
}

function getRouteUnlockUrl(type, language)
{
    if (type === Constants.TypeTopo.FALAISE) {
        return ApiUrls.SECTOR_ROUTE_UNLOCK.replace('{lng}', language);
    }

    if (type === Constants.TypeTopo.GRANDEVOIE) {
        return ApiUrls.MULTIPITCH_ROUTE_UNLOCK.replace('{lng}', language);
    }
}
