import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from "react";
import HttpApi from 'i18next-http-backend';

import fr from "./config/i18n/pwa.fr.js";
import en from "./config/i18n/pwa.en.js";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const detectionOptions = {
    order: ['path', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag',],
};

const resources = {
    "en": {
        "translation": en,
    },
    "fr": {
        "translation": fr,
    }
}

i18n
    .use(HttpApi)
// load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// learn more: https://github.com/i18next/i18next-xhr-backend
//.use(Backend)
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: {
            'fr-FR': ['fr'],
            'en-US': ['en'],
            default: ['en'],
        },
        supportedLngs: ["fr", "en"],
        debug: false,
        ns: ['translation'],
        defaultNS: 'translation',
        resources: resources,
        detection: detectionOptions,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // backend: {
        //     // for all available options read the backend's repository readme file
        //     loadPath: ApiUrls.API_URL + '/{{lng}}/locale',
        //     allowMultiLoading: true,
        //     ajax: useLocale,
        // },
        nonExplicitSupportedLngs: false,
        cleanCode: true,
        react: {
            useSuspense: false
        }
        // react: {
        //     wait: true
        // }
    }, (err, t) => {
        //console.log(err, t);
    });

export default i18n;
