import Actions from "../config/actions";

const initialState = {
    isOnline: true,
    unlockedGuidebooks: [],
    url: '/',
    tokens: {},
    errorOccured: false,
    showInstallMessage: true,
    clickInstallMessage: false,
    bottomNavigationItems: true,
    showFullscreenSpinner: false,
};

//TODO - Add global error management ?

/**
 * @param state
 * @param action
 * @returns {*}
 */
const common = (state = initialState, action) => {
    let unlocked = state.unlockedGuidebooks;

    switch (action.type) {
        case Actions.CONNEXION_CHANGE:
            return Object.assign({}, state, {
                    isOnline: action.isOnline,
                }
            );

        case Actions.UNLOCK_GUIDEBOOK:
            if (typeof state.unlockedGuidebooks === 'undefined' || state.unlockedGuidebooks === null) {
                state = {
                    ...initialState,
                    ...state,
                }
            }
            if (typeof unlocked !== 'undefined' && unlocked !== null) {
                if (!unlocked.includes(action.guidebookSlug)) {
                    unlocked.push(action.guidebookSlug);
                }
                return Object.assign({}, state, {
                        unlockedGuidebooks: unlocked,
                    }
                );
            } else {
                return state;
            }

        case Actions.LOCK_GUIDEBOOK:
            if (typeof state.unlockedGuidebooks === 'undefined' || state.unlockedGuidebooks === null) {
                state = {
                    ...initialState,
                    ...state,
                }
            }
            if (typeof unlocked !== 'undefined' && unlocked !== null) {
                if (unlocked.indexOf(action.guidebookSlug) >= 0) {
                    unlocked.splice(unlocked.indexOf(action.guidebookSlug), 1);
                }
                return Object.assign({}, state, {
                        unlockedGuidebooks: unlocked,
                    }
                );
            } else {
                return state;
            }
        case Actions.SET_GLOBAL_ERROR:
            return {
                ...state,
                errorOccured: action.hasError,
            }
        case Actions.TOGGLE_INSTALL_MESSAGE:
            return {
                ...state,
                showInstallMessage: action.showInstallMessage,
                clickInstallMessage: action.clickInstallMessage
            }
        case Actions.STORE_TOKEN:
            console.log('store_token', action);
            if (!state.tokens) {
                console.log('ré-init Tokens localstorage');
                state = {
                    ...initialState,
                    ...state,
                }
            }

            let data = state;
            data.tokens[action.guidebookSlug] = action.apiToken;

            return data

        case Actions.CHANGE_BOTTOM_NAVIGATION:
            return {
                ...state,
                bottomNavigationItems: action.bottomNavigationItems,
            }

        case Actions.SHOW_FULLSCREEN_SPINNER:
            return {
                ...state,
                showFullscreenSpinner: true,
            }

        case Actions.HIDE_FULLSCREEN_SPINNER:
            return {
                ...state,
                showFullscreenSpinner: false,
            }

        default:
            return state;
    }
}

export default common;
