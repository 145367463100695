import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

const BookmarkComponent =({t, bookmark, setOpenStoreRouteDrawer}) => {
    const hasBookmark = bookmark?.title && bookmark?.url;

    return <List component="nav" aria-label="secondary link">
        <ListItem>
            <ListItemText
                primary={hasBookmark ? <Link to={bookmark.url}>{bookmark.title}</Link> : t("Navbar.saveRoute.bookmarkEmpty")}
                onClick={() => {
                if (hasBookmark) {
                    setOpenStoreRouteDrawer(false);
                }}}/>

            <ListItemSecondaryAction
                component={hasBookmark ? Link : null} to={hasBookmark ? bookmark.url : null} onClick={() => {
                if (hasBookmark) {
                    setOpenStoreRouteDrawer(false);
                }}}>
                <IconButton edge="end" aria-label="loading">
                    <FontAwesomeIcon icon={["fas", "bookmark"]} className="secondary" />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    </List>
};

const mapStateToProps = (state) => {
    return {
        bookmark: state.navbar.bookmark,
    }
};

export default compose(
        withTranslation(),
        connect(
            mapStateToProps,
            null
        ))(BookmarkComponent);
