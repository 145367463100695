import Actions from "../config/actions";
import {goBack} from "../utils/helpers";

const initialState = {
    title: 'Choix du topo',
    savedRoute: [],
    goBackUrl: null,
    routeListOrigin: '/en/routes',
    routePresentation: null,
    language: null,
    showMenuNavBar: true,
    bookmark: {
        title: null,
        url: null,
        id: null
    },
};


const navbar = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CHANGE_TITLE:
            return Object.assign({}, state, {
                    title: action.title,
                }
            )

        case Actions.ADD_SAVED_ROUTE:
            let savedRoute = [...state.savedRoute, action.route].sort((a,b) => {return a.label.localeCompare(b.label)})
            // Vérification de l'unicité des routes
            savedRoute = savedRoute.filter((item, index) => savedRoute.indexOf(item) === index);
            return {
                ...state,
                savedRoute: savedRoute,
            }

        case Actions.REMOVE_SAVED_ROUTE:
            return {
                ...state,
                savedRoute: state.savedRoute.filter((el) => el.slug !== action.route.slug),
            }

        case Actions.SET_GO_BACK_URL:
            let url = action.url;
            if (url === "") {
                url = "/";
            }

            return {
                ...state,
                goBackUrl: goBack(url, action.keepLink),
            }
        case Actions.SET_ROUTE_LIST_ORIGIN:
            return {
                ...state,
                routeListOrigin: action.routeListOrigin
            }
        case Actions.SET_ROUTE_PRESENTATION:
            return {
                ...state,
                routePresentation: action.routePresentation
            }
        case Actions.CHANGE_LANG:
            return {
                ...state,
                language: action.lang,
            }

        case Actions.SHOW_MENU_NAV_BAR:
            return {
                ...state,
                showMenuNavBar: action.show,
            }

        case Actions.UPDATE_BOOKMARK:
            return {
                ...state,
                bookmark: action.bookmark,
            }
        default:
            return state
    }
}
export default navbar;