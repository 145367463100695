import React, {useEffect} from 'react';
import { withTranslation} from 'react-i18next';
import {Alert, Typography} from '@mui/material';
import {compose} from "redux";
import {connect} from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useFlashMessageAck} from "../../../hooks/useFlashMessages";
import {toast} from "react-toastify";
import {MuiButton} from "../../MuiButton";

const AlertFlashMessage = ({flashMessage}) => {
    return <>
            {flashMessage.severity && <Alert
                severity={flashMessage.severity}
                style={{marginTop: "15px", marginBottom: "15px"}}>
                <Typography variant="h3" gutterBottom dangerouslySetInnerHTML={{__html: flashMessage.label}}/>
                <p className="wysiwyg" dangerouslySetInnerHTML={{__html: flashMessage.content}}/>
                {/*<Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{__html:flashMessage.content}}/>*/}
            </Alert>}
        {!flashMessage.severity && <>
            <Typography variant="h3" gutterBottom dangerouslySetInnerHTML={{__html: flashMessage.label}}/>
            <p className="wysiwyg" dangerouslySetInnerHTML={{__html: flashMessage.content}}/>
            {/*<Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{__html:flashMessage.content}}/>*/}
        </>}
    </>;
};

const mapStateToProps = (state) => {
    return {}
};

export default compose(
    withTranslation(),
    connect(
        mapStateToProps
    ))(AlertFlashMessage);
