import React from 'react';
import { withTranslation} from 'react-i18next';
import {compose} from "redux";
import {connect} from "react-redux";
import DialogFlashMessage from "./DialogFlashMessage";
import {useFlashMessages} from "../../../hooks/useFlashMessages";
import AlertFlashMessage from "./AlertFlashMessage";
import {useAuth} from "../../../context/auth-context";

const FlashMessages = ({
    i18n,
    regionIds = []
}) => {
    const auth = useAuth();
    const { status, data, error } = useFlashMessages(i18n.language, auth.isAuthenticated);
    if (!auth.isAuthenticated) {
        return null;
    }

    if (!Array.isArray(data?.flash_messages) || data.flash_messages.length === 0) {
        return null;
    }

    const globalFlashMessages = (regionIds.length === 0) ? data.flash_messages.filter((f) => f.regionIds.length === 0)
        //If array intersect
        : data.flash_messages.filter((f) => f.regionIds.filter((regionId) => regionIds.some((id) => id == regionId)).length > 0);

    if (globalFlashMessages.length === 0) {
        return null;
    }

    const flashMessage = globalFlashMessages[0];

    if (regionIds.length === 0) {
        return <DialogFlashMessage flashMessage={flashMessage}/>;
    } else {
        return <AlertFlashMessage flashMessage={flashMessage}/>;
    }

    // if (flashMessages.length === 0) {
    //     return null;
    // }
    //
    // return (<>
    //
    //         {flashMessages.map((flashMessage) => {
    //             console.log(flashMessage);
    //             return (<Alert
    //                 severity={flashMessage.severity}
    //                 style={{marginTop: "15px", marginBottom: "15px"}}>
    //                 <Typography variant="h6">{flashMessage.label}</Typography>
    //                 <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{__html:flashMessage.content}}/>
    //             </Alert>);
    //         })}
    //     </>
    // );
};

const mapStateToProps = (state) => {
    return {

    }
};

export default compose(
    withTranslation(),
    connect(
        mapStateToProps
    ))(FlashMessages);
