import axios from 'axios'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {ApiUrls} from "../config/urls";
import {getStoreRouteCacheTime} from "../utils/helpers";
import Constants from "../config/constants";

const fetchCliff = (queryKey, slug) => {
    let path = typeof queryKey === 'string' ? queryKey : queryKey[0];

    return axios.get(`${path}/${slug}`).then((res) => res.data?.data ? res.data.data : res.data)
}

const useCliffQuery = (language, slug, url, options) => {
    options.queryKey = [url, slug];
    options.queryFn = ({queryKey}) => fetchCliff(queryKey, slug);
    return useQuery(options);
}

export function useCliff(language, slug, unlock, options = {}) {
    const url = ApiUrls.CLIFF.replace('{lng}', language);
    options.enabled = Boolean(url && slug && unlock === false);

    return useCliffQuery(language, slug, url, options);
}

export function useCliffUnlock(language, slug, unlock, user = {}) {
    const queryClient = useQueryClient();
    let options = {...queryClient.getDefaultOptions().queries};

    if (user?.customerRoutes) {
        const storeRoutes = Array.isArray(user.customerRoutes) ? user.customerRoutes : [];
        const storeRoute = storeRoutes.find((route) => {
            if (route.type === Constants.TypeTopo.FALAISE && route.route?.cliff?.slug === slug) {
                return route;
            }
        });

        const cacheTime = getStoreRouteCacheTime(storeRoute?.endValidationDate);
        if (storeRoute && cacheTime > 0) {
            options.refetchOnWindowFocus = false;
            options.cacheTime = cacheTime;
            options.staleTime = cacheTime;
            options.networkMode ='offlineFirst';
        }
    }

    const url = ApiUrls.CLIFF_UNLOCK.replace('{lng}', language);
    options.enabled = Boolean(url && slug && unlock && user?.email);
    return useCliffQuery(language, slug, url, options);
}