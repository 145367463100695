import Actions from "../config/actions";

const initialState = {
    cart: null,
};


/**
 * @param state
 * @param action
 * @returns {*}
 */
const shop = (state = initialState, action) => {

    switch (action.type) {
        case Actions.SET_CART:
            console.log(Object.assign({}, state, {cart: action.payload}));
            return Object.assign({}, state, {cart: action.payload});

        default:
            return state;
    }
}

export default shop;