import React, {useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {useCliffUnlock} from "../../hooks/useCliff";

/**
 * Composant de SaveCliffComponent
 */
const SaveCliffComponent = ({i18n, slug, user, setStatus}) => {
    const { status, data } = useCliffUnlock(i18n.language, slug, true, user);

    useEffect(() => {
        if (setStatus !== undefined) {
            if (status !== "success" && data) {
                setStatus("success");
            } else {
                setStatus(status);
            }
        }
    }, [status]);

    return (<></>);
};

export default withTranslation()(SaveCliffComponent);